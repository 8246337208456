
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    position: relative;

    h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: bold;

        @include lg {
            font-size: 30px;
            line-height: 45px;
        }
    }

    p {
        font-size: 16px;
        line-height: 27px;

        margin-top: 15px;

        @include lg {
            font-size: 18px;
            line-height: 32px;
        }
    }
}
