$camplejeuneapplication-dark-blue: #222e59;
$camplejeuneapplication-hover: #fff5f5;
$camplejeuneapplication-dark-red: #ae332f;
$camplejeuneapplication-grey: #eaeaea;

$stickTitleColor:#000;
$stickBtnTxtColor:#fff;
$stickBtnBgColor:#ae332f;
$stickBtnBorderColor:#ae332f;


:export {
    darkBlue: $camplejeuneapplication-dark-blue;
    hover: $camplejeuneapplication-hover;
    darkRed: $camplejeuneapplication-dark-red;
    grey: $camplejeuneapplication-grey;
    stickTitleColor:$stickTitleColor;
    stickBtnTxtColor:$stickBtnTxtColor;
    stickBtnBgColor:$stickBtnBgColor;
    stickBtnBorderColor:$stickBtnBorderColor;
}
